import styles from "./styles.module.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer class={styles.footer}>
      <p>
        <span> YAHABLE FIRE </span> SAFETY & CONSULTING
      </p>
      <a
        href="http://mail.google.com/mail/u/1/?view=cm&fs=1&to=nwobododominic45@gmail.com&tf=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        CONTACT US
      </a>
      &copy; {currentYear}. All rights reserved.
    </footer>
  );
}

export default Footer;
