import styles from "./styles.module.css";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
export default function Navbar() {
  let [activeBar, setActiveBar] = useState(false);
  let [width, setWidth] = useState(0);
  let targetElement = useRef();
  function toggleClass() {
    const menuIcon = document.querySelector("#toggle-icon");

    menuIcon?.classList.toggle(styles.rotateIcon);
    setActiveBar(!activeBar);
  }

  useEffect(() => {
    if (targetElement.current) {
      setInterval(() => {
        setWidth(targetElement.current.offsetWidth);
      }, 700);
    }
  }, [width]);
  let pathname = window.location.pathname;

  let links = [];

  if (pathname === "/") {
    links = [
      ["/about-us", "About"],
      ["/service", "Service"],
    ];
  } else if (pathname === "/about-us") {
    links = [
      ["/", "Home"],
      ["/service", "Service"],
    ];
  } else if (pathname === "/service") {
    links = [
      ["/", "Home"],
      ["/about-us", "About"],
    ];
  }

  return (
    <nav ref={targetElement} className={styles.navbar}>
      <h5>Yahable Fire Safety & Consulting</h5>

      <div className={styles.linkContainer}>
        <div className={styles.links}>
          {links.map((link, index) => {
            return width > 860 ? (
              <a key={link[1]} href={link[0]}>
                {link[1]}
              </a>
            ) : (
              <motion.a
                style={{
                  display: !activeBar ? "none" : "block",
                }}
                initial={{ x: 1000 }}
                animate={{ x: activeBar ? 0 : 1000 }}
                transition={{ delay: index === 1 ? 0.5 : 0 }}
                key={link[1]}
                href={link[0]}
              >
                {link[1]}
              </motion.a>
            );
          })}
        </div>
        <a
          className={styles.book}
          href="https://wa.link/usf0r5"
          target="_blank"
          rel="noopener noreferrer"
        >
          BOOK CONSULTING
        </a>
      </div>
      <div id="toggle-icon" className={styles.toggleIcon} onClick={toggleClass}>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
      </div>
    </nav>
  );
}
