import { motion } from "framer-motion";
import mdProfilePic from "../assets/MDProfilePic.png";
import styles from "./styles.module.css";
import { useEffect, useRef, useState } from "react";

const TextWriter = ({ text }) => {
  const textVariants = {
    hidden: { opacity: 0 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 50,
      },
    }),
  };

  return (
    <div className={styles.textWriter}>
      {text.split("").map((char, index) => (
        <motion.span
          key={index}
          custom={index}
          variants={textVariants}
          initial="hidden"
          animate="visible"
        >
          {char}
        </motion.span>
      ))}
    </div>
  );
};

export default function About() {
  let text = ` Yahable Fire Safety and Consulting, annex of Yahable Fire Safety Company was Founded by Dominic
  Nwobodo, a former Deputy Controller of Fire(from Federal Fire
  Service of Nigeria) A member Institute Of Fire Engineering, UK. A
  Master degree in Disaster and Risk Management`;
  const imgArray = [
    "abujaEnterpriseAgency",
    "abujaInvestment",
    "apdc",
    "familyHomeFund",
    "familyHomeFund2",
    "foreignAffairs",
    "novogas",
    "Ominiswift",
  ];

  const [clientWidth, setClientWidth] = useState(0);
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    if (carousel.current) {
      setWidth(carousel.current.clientWidth);
      setClientWidth(
        carousel.current.clientWidth - carousel.current.offsetWidth + 60
      );
    }
  }, [width]);

  return (
    <div className={styles.aboutContainer}>
      <h2 className={styles.infoHeading}>About Our Managerial Director </h2>
      <div className={styles.about}>
        <img alt="" width={300} height={300} src={mdProfilePic} />

        <TextWriter text={text} />
      </div>
      <div>
        <h3 style={{ textAlign: "center", marginBottom: "1rem" }}>
          Companies&apos; Clienteles{" "}
        </h3>
        <div>
          <motion.div
            ref={carousel}
            drag={width > 860 ? "x" : false}
            dragConstraints={
              width > 860
                ? {
                    right: 0,
                    left: -clientWidth,
                  }
                : false
            }
            className={styles.imgSlide}
          >
            {imgArray.map((img) => {
              return (
                <div style={{ marginLeft: "4rem" }} key={img}>
                  <img
                    height={100}
                    width={100}
                    alt={img}
                    src={`/about/${img}.png`}
                  />
                </div>
              );
            })}
          </motion.div>
        </div>
      </div>
      <h3 className={styles.programHeader}>Program Training</h3>
      <div className={styles.videoContainer}>
        <video height={300} controlsList="nodownload" controls>
          <source src="/about/safetyTraining.mp4" type="video/mp4" />
        </video>
        <video height={300} controlsList="nodownload" controls>
          <source src="/about/safetyTraining2.mp4" type="video/mp4" />
        </video>
        <video height={300} controlsList="nodownload" controls>
          <source src="/about/safetyTraining3.mp4" type="video/mp4" />
        </video>
        <video height={300} controlsList="nodownload" controls>
          <source src="/about/safetyTraining4.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
