import introImage from "./assets/intro.jpg";
import locationImage from "./assets/location.png";
import styles from "./page.module.css";

function Home() {
  return (
    <main class={styles.page}>
      <div>
        <h1 class={styles.header}>
          Prioritizing Preventive Fire Hazards Measures with{" "}
          <span> Yahable Fire Safety And Consultation Ltd </span>
        </h1>
        <small>
          The Safety And Well-Being Of The Community Is Our Priority
        </small>
      </div>

      <div class={styles.image}>
        <img alt="" src={introImage} />
      </div>

      <p class={styles.locationText}>
        <img src={locationImage} alt="" /> Located At:{" "}
        <span>
          Kuje, Federal Capital Territory. <em>NIGERIA</em>
        </span>
      </p>
    </main>
  );
}

export default Home;
